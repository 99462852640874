import { createFeature, createReducer, on } from '@ngrx/store';

import * as fromGenerated from '../../_generated';

import { AuthActions } from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: fromGenerated.UserView | null;
  isLoading: boolean | null;
  error: string | null;
}

export const initialState: AuthState = {
  user: null,
  isLoading: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.authenticateUser, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthActions.authenticateUserSuccess, (state, { user }) => ({
    ...state,
    user: { ...user },
    error: null,
    isLoading: false,
  })),
  on(AuthActions.authenticateUserFailure, (state, { error }) => ({
    ...state,
    error,
    user: null,
    isLoading: false,
  })),
  on(AuthActions.refreshTokensSuccess, (state) => ({
    ...state,
    error: null,
  })),
  on(AuthActions.refreshTokensFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer,
});
